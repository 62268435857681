/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #333;
}

img {
  max-width: 100%;
  height: auto;
}

/* Container */
.container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

/* Header */
header {
  background-color: #121212;
  padding: 20px 0;
}

header .logo img {
  width: 65px;
}

header nav {
  float: right;
}

header nav ul {
  list-style: none;
}

header nav ul li {
  display: inline-block;
  margin-left: 20px;
}

header nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

header nav ul li a.btn {
  background-color: #ff6b6b;
  padding: 10px 20px;
  border-radius: 5px;
}

header nav ul li a:hover,
header nav ul li a.btn:hover {
  color: #ff6b6b;
}

header nav ul li a.btn:hover {
  background-color: #ff4c4c;
  color: #fff;
}

/* Hero Section */
.hero {
  background-color: #1e1e1e;
  color: #fff;
  padding: 100px 0;
  display: flex;
  align-items: center;
}

.hero .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.hero .hero-text {
  flex: 1 1 50%;
  padding-right: 20px;
}

.hero .hero-video {
  flex: 1 1 50%;
  padding-left: 20px;
}
.hero .hero-text p {
  font-size: 18px;
  margin-bottom: 30px;
}

.hero .hero-text .btn {
  background-color: #ff6b6b;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
}

.hero .hero-text .btn:hover {
  background-color: #ff4c4c;
}

.hero .hero-video {
  flex: 1 1 50%;
  padding-left: 20px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero .hero-text,
  .hero .hero-video {
      flex: 1 1 100%;
      padding: 0;
  }

  .hero {
      padding: 60px 0;
  }
}
/* Features Section */
.features {
  padding: 80px 0;
  text-align: center;
}

.features h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

.feature-cards {
  display: flex;
  justify-content: space-between;
}

.feature-cards .card {
  background-color: #f4f4f4;
  padding: 40px 20px;
  width: 30%;
  border-radius: 10px;
}

.feature-cards .card i {
  font-size: 50px;
  color: #ff6b6b;
  margin-bottom: 20px;
}

.feature-cards .card h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.feature-cards .card p {
  font-size: 16px;
  color: #555;
}

/* Pricing Section */
.pricing {
  background-color: #f9f9f9;
  padding: 80px 0;
  text-align: center;
}

.pricing h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
}

.pricing-card {
  background-color: #fff;
  border: 2px solid #ff6b6b;
  padding: 40px 20px;
  width: 40%;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.pricing-card::before {
  content: "Most Popular";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff6b6b;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.pricing-card-header {
  margin-bottom: 30px;
}

.pricing-card h3 {
  font-size: 28px;
  margin-bottom: 10px;
}

.pricing-card .price {
  font-size: 48px;
  color: #ff6b6b;
  margin-bottom: 10px;
}

.pricing-card .price span {
  font-size: 24px;
  color: #333;
}

.pricing-card ul {
  list-style: none;
  margin-bottom: 30px;
  text-align: left;
}

.pricing-card ul li {
  font-size: 16px;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.pricing-card ul li::before {
  content: "\f058";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  color: #ff6b6b;
}

.pricing-card .btn {
  background-color: #ff6b6b;
  color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
}

.pricing-card .btn:hover {
  background-color: #ff4c4c;
}

/* How It Works Section */
.how-it-works {
  padding: 80px 0;
  text-align: center;
}

.how-it-works h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

.steps {
  display: flex;
  justify-content: space-between;
}

.steps .step {
  width: 30%;
  padding: 20px;
}

.steps .step span {
  display: inline-block;
  background-color: #ff6b6b;
  color: #fff;
  font-size: 24px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.steps .step h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.steps .step p {
  font-size: 16px;
  color: #555;
}

/* Testimonials Section */
.testimonials {
  padding: 80px 0;
  text-align: center;
  background-color: #1e1e1e;
  color: #fff;
}

.testimonials h2 {
  font-size: 36px;
  margin-bottom: 50px;
}

.testimonial-cards {
  display: flex;
  justify-content: space-between;
}

.testimonial-cards .testimonial {
  width: 30%;
  background-color: #333;
  padding: 30px;
  border-radius: 10px;
}

.testimonial-cards .testimonial p {
  font-size: 16px;
  margin-bottom: 20px;
}

.testimonial-cards .testimonial h4 {
  font-size: 18px;
  font-weight: 700;
}

/* Call to Action Section */
.cta {
  background-color: #ff6b6b;
  color: #fff;
  padding: 60px 0;
  text-align: center;
}

.cta h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.cta .btn {
  background-color: #fff;
  color: #ff6b6b;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
}

.cta .btn:hover {
  background-color: #f1f1f1;
}

/* Footer */
footer {
  background-color: #121212;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

footer .footer-logo img {
  width: 100px;
  margin-bottom: 20px;
}

footer .social-media a {
  color: #fff;
  margin: 0 10px;
  font-size: 20px;
}

footer .social-media a:hover {
  color: #ff6b6b;
}

footer p {
  margin-top: 20px;
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  header nav {
      float: none;
      text-align: center;
      margin-top: 20px;
  }

  header nav ul li {
      display: block;
      margin: 10px 0;
  }

  .hero .hero-text,
  .hero .hero-image {
      float: none;
      width: 100%;
      text-align: center;
  }

  .feature-cards,
  .steps,
  .testimonial-cards {
      flex-direction: column;
      align-items: center;
  }

  .feature-cards .card,
  .steps .step,
  .testimonial-cards .testimonial {
      width: 80%;
      margin-bottom: 30px;
  }

  .pricing-card {
      width: 80%;
  }

  .pricing-card::before {
      top: -15px;
  }
}

.pishu-bubble {
  padding-top: 35px;
}

/* Sign Up Section */
.signup-section {
  padding: 80px 0;
  text-align: center;
}

.signup-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.signup-section form {
  width: 50%;
  margin: auto;
  background-color: #f9f9f9;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.signup-section .input-group {
  margin-bottom: 20px;
  text-align: left;
}

.signup-section .input-group label {
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.signup-section .input-group i {
  margin-right: 10px;
  color: #ff6b6b;
}

.signup-section .input-group input {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.signup-section .input-group input:focus {
  border-color: #ff6b6b;
  outline: none;
}

.signup-section .btn {
  width: 100%;
  padding: 15px;
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.signup-section .btn:hover {
  background-color: #ff4c4c;
}

.signup-section p {
  margin-top: 20px;
  font-size: 16px;
}

.signup-section p a {
  color: #ff6b6b;
  text-decoration: none;
}

.signup-section p a:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.signup-section, .success-section {
  padding: 80px 0;
  text-align: center;
}

.signup-section h2, .success-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.signup-section form {
  width: 50%;
  margin: auto;
  background-color: #f9f9f9;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.signup-section .input-group {
  margin-bottom: 20px;
  text-align: left;
}

.signup-section .input-group label {
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.signup-section .input-group i {
  margin-right: 10px;
  color: #ff6b6b;
}

.signup-section .input-group input {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.signup-section .input-group input:focus {
  border-color: #ff6b6b;
  outline: none;
}

.signup-section .btn {
  width: 100%;
  padding: 15px;
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.signup-section .btn:hover {
  background-color: #ff4c4c;
}

.signup-section p {
  margin-top: 20px;
  font-size: 16px;
}

.signup-section p a {
  color: #ff6b6b;
  text-decoration: none;
}

.signup-section p a:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.success-section .btn {
  padding: 15px 30px;
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.success-section .btn:hover {
  background-color: #ff4c4c;
}

.success-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

/* Sign Up Section */
.signup-section {
  padding: 80px 0;
  text-align: center;
}

.signup-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.signup-section form {
  width: 50%;
  margin: auto;
  background-color: #f9f9f9;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.signup-section .input-group {
  margin-bottom: 20px;
  text-align: left;
}

.signup-section .input-group label {
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.signup-section .input-group i {
  margin-right: 10px;
  color: #ff6b6b;
}

.signup-section .input-group input {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.signup-section .input-group input:focus {
  border-color: #ff6b6b;
  outline: none;
}

.signup-section .btn {
  width: 100%;
  padding: 15px;
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.signup-section .btn:hover {
  background-color: #ff4c4c;
}

.signup-section p {
  margin-top: 20px;
  font-size: 16px;
}

.signup-section p a {
  color: #ff6b6b;
  text-decoration: none;
}

.signup-section p a:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

/* Dashboard Section */
.dashboard-section {
  padding: 80px 0;
  text-align: center;
}

.dashboard-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.dashboard-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

.dashboard-section .btn {
  padding: 15px 30px;
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.dashboard-section .btn:hover {
  background-color: #ff4c4c;
}

/* Footer */
footer {
  background-color: #f9f9f9;
  padding: 40px 0;
}

footer .footer-content {
  text-align: center;
}

footer .footer-logo img {
  height: 50px;
  margin-bottom: 20px;
}

footer .social-media a {
  display: inline-block;
  margin: 0 10px;
  color: #333;
  font-size: 18px;
}

footer .social-media a:hover {
  color: #ff6b6b;
}

footer p {
  margin-top: 20px;
  font-size: 14px;
  color: #777;
}
